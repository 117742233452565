import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Data, Route, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { UserRole } from '../enums/user-role';
import { UserProfile } from '../models/user-profile';
import { CurrentOrganizationService } from '../services/current-organization.service';
import { UserAuthService } from '../services/user-auth.service';
import { routePaths } from '../utils/route-paths';

/**
 * Guard that checks user role.
 * Available roles should be in data.userTypes field. If none userRoles specified, no users can enter except Global Admin.
 * @example
 * {
 *   path: 'properties',
 *   canActivate: [RestrictUserRolesGuard],
 *   data: {
 *     userRoles: [UserRole.Engineer, UserRole.Developer],
 *   },
 *   ...
 * }
 */
@Injectable({
  providedIn: 'root',
})
export class RestrictUserRolesGuard implements CanActivate, CanLoad {
  private readonly userService = inject(UserAuthService);

  private readonly router = inject(Router);

  private readonly currentOrganizationService = inject(CurrentOrganizationService);

  /**
   * Determine if route could be achieved.
   * @param route Activated route.
   */
  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.canNavigate(route);
  }

  /**
   * Determine if route could be loaded.
   * @param route Route.
   */
  public canLoad(route: Route): Observable<boolean | UrlTree> {
    return this.canNavigate(route);
  }

  /**
   * Common function to check for route activate or route load.
   * @param route Activated Route or Route.
   */
  private canNavigate(route: ActivatedRouteSnapshot | Route): Observable<boolean | UrlTree> {
    const allowedRoles = this.getAllowedUserRoles(route.data);
    return this.checkRole(allowedRoles);
  }

  /**
   * Check that user has permissions to access the page.
   * @param allowedRoles Allowed user roles.
   */
  private checkRole(allowedRoles: UserRole[]): Observable<boolean | UrlTree> {
    return combineLatest([
      this.userService.currentUser$.pipe(first()),
      this.currentOrganizationService.organization$,
    ])
      .pipe(
        map(([user, organization]) => {
          const userRoles = organization?.roles ?? [];
          if (Boolean(user?.isGlobalAdmin) || userRoles.some(role => allowedRoles.includes(role))) {
            return true;
          }

          const redirectLink = this.getRedirectLink(user, userRoles);

          // Access denied, perform redirect.
          return this.router.createUrlTree(redirectLink);
        }),
      );
  }

  /**
   * Get allowed user roles.
   * @param data Router data.
   */
  private getAllowedUserRoles(data?: Data): UserRole[] {
    return data?.['userRoles'] || [];
  }

  private getRedirectLink(user: UserProfile | null, userRoles: readonly UserRole[]): string[] {
    if (user === null) {
      return routePaths.login;
    }

    const redirectRoutesMap: Partial<Record<UserRole, string[]>> = {
      [UserRole.Developer]: routePaths.resources,
    };

    return userRoles.map(role => redirectRoutesMap[role]).filter(Boolean)[0] ?? routePaths.login;
  }
}
